import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import Top from 'Components/top';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import Pagination from 'Components/pagination';
import StockListItem from './components/StockListItem.jsx';
import ModalAdvancedSearch from 'Components/modal/advanced-search';
import { setStockCheckItemCode } from 'Redux/actions_ui';
import { getStock, getLotNo, getLotItem } from 'Redux/actions';
import styles from './PageStockCheck.less';

class PageStockCheck extends PureComponent {
	constructor() {
		super();
		this.state = {
			current: 'overall',

			page: 1,
			size: 20,
			keyword: process.env.DEFAULT_ITEM_CODE || '',
			is_active: true,
			is_stock_check: true,
			exclude_normal_items: true,
			param: new Map({
				sort_direction: 'asc',
				sort_column: 'item_name_en',
				is_current_shop: false,
			}),

			tempItem: null,
			itemCode: null,
			lotPage: 1,

			showModalAdvancedSearch: false,
		};
		this.fetchData = this.fetchData.bind(this);
		this.getStock = this.getStock.bind(this);
		this.getItem = this.getItem.bind(this);
		this.getLotNo = this.getLotNo.bind(this);
		this.isLoading = this.isLoading.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onChangeLotPageHandler = this.onChangeLotPageHandler.bind(this);
		this.onChangeParamHandler = this.onChangeParamHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onSelectItemHandler = this.onSelectItemHandler.bind(this);
		this.onToggleModalAdvancedSearchHandler = this.onToggleModalAdvancedSearchHandler.bind(this);
	}

	componentDidMount() {
		const { stockCheckItemCode } = this.props;
		const search = queryString.parse(this.props.location.search);
		if (search && search.item_code) {
			this.setState({
				keyword: search.item_code,
				itemCode: search.item_code,
			}, () => {
				this.getItem();
				this.getLotNo();
			});
		} else if (stockCheckItemCode && stockCheckItemCode.item_code) {
			this.props.history.push('stock-check?' + queryString.stringify({
				item_code: stockCheckItemCode.item_code,
			}));
			this.setState({
				keyword: stockCheckItemCode.item_code,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const search = this.props.location.search;
		const prevSearch = prevProps.location.search;
		const lotItemInfo = this.props.lotItemInfo;
		const prevLotItemInfo = prevProps.lotItemInfo;
		if (search !== prevSearch) {
			this.fetchData();
		}

		if (lotItemInfo && lotItemInfo.data && !Array.isArray(lotItemInfo.data) && lotItemInfo !== prevLotItemInfo) {
			this.setState({
				tempItem: lotItemInfo.data,
			});
		}
	}

	fetchData() {
		const search = queryString.parse(this.props.location.search);
		if (search && search.item_code) {
			this.setState({
				itemCode: search.item_code,
			}, () => {
				this.getItem();
				this.getLotNo();
			});
		} else {
			this.setState({
				page: 1,
				tempItem: null,
				itemCode: null,
				lotPage: 1,
			}, this.getStock);
		}
	}

	getStock() {
		const { page, size, is_active, is_stock_check, exclude_normal_items, keyword } = this.state;
		let param = {
			payload: {
				...this.state.param.toJS(),
				page,
				size,
				keyword,
				is_active,
				is_stock_check,
				exclude_normal_items,
			},
		};
		this.setState({
			current: 'overall',
		});
		this.props.getStock(param);
	}

	getItem() {
		const { itemCode } = this.state;
		this.props.getLotItem({
			payload: {
				value: itemCode
			}
		});
	}

	getLotNo() {
		const { itemCode, lotPage } = this.state;
		const page = lotPage;
		let param = {
			payload: {
				item_code: itemCode,
				page,
				is_current_shop: this.state.param.get('is_current_shop'),
			},
		};
		if (!param.payload.is_current_shop && this.state.param.get('shops') && this.state.param.get('shops').length > 0) {
			param.payload.shops = this.state.param.get('shops').join(',');
		}
		this.props.getLotNo(param);
		this.setState({
			current: 'details',
			lotPage: 1,
		});
	}

	isLoading() {
		const { stockInfo, lotNoInfo } = this.props;
		return stockInfo.isFetching || lotNoInfo.isFetching;
	}

	onChangeKeywordHandler(e) {
		this.setState({
			page: 1,
			keyword: e.target.value,
		});
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.getStock);
	}

	onChangeLotPageHandler(data) {
		this.setState({
			lotPage: data.selected + 1,
		}, this.getLotNo);
	}

	onChangeParamHandler(data) {
		const { param } = this.state;
		this.setState({
			current: 'overall',
			param: param.merge(data),
			showModalAdvancedSearch: false,
		}, this.getStock);
		this.props.history.push('stock-check');
		this.props.setStockCheckItemCode(null);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		this.getStock();
		this.props.history.push('stock-check');
		this.props.setStockCheckItemCode(null);
	}

	onSelectItemHandler(item) {
		this.props.history.push('stock-check?' + queryString.stringify({
			item_code: item.item_code
		}));
		this.props.setStockCheckItemCode({
			item_code: item.item_code,
		});
	}

	onToggleModalAdvancedSearchHandler() {
		if (!this.isLoading()) {
			const { showModalAdvancedSearch } = this.state;
			this.setState({
				showModalAdvancedSearch: !showModalAdvancedSearch,
			});
		}
	}

	render() {
		const { current, keyword, param, tempItem, showModalAdvancedSearch } = this.state;
		const { stockInfo, lotNoInfo } = this.props;
		const isLoading = this.isLoading();
		const info = current === 'details' ? lotNoInfo : stockInfo;

		return (
			<Fragment>
				<div className="uk-flex uk-flex-column">
					<Top
						name="stockCheck"
						desc={ current === 'details' && tempItem ? tempItem.item_code : '' }
					/>

					<div className={ classnames('uk-flex', 'uk-padding-small', styles.searchBar) }>
						<div className="uk-flex uk-flex-1">
							<div className="uk-inline uk-width-expand">
								<div className={ styles.searchContainer }>
									<form onSubmit={ this.onSubmitHandler }>
										<div className={ styles.search }>
											<a onClick={ this.onSubmitHandler } className="uk-form-icon">
												<FontAwesomeIcon icon={ faSearch } className="uk-text-muted" />
											</a>
											<input
												type="text"
												value={ keyword }
												placeholder={ loc.searchHelpText }
												className="uk-input uk-border-rounded"
												onChange={ this.onChangeKeywordHandler }
												disabled={ isLoading }
											/>
										</div>
										<div className={ styles.btnSearch }>
											<button
												type="submit"
												disabled={ isLoading }
												className="uk-button uk-button-small uk-button-secondary"
											>
												{ loc.search }
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className={ classnames('uk-padding-remove-vertical', 'uk-text-middle', styles.button) } onClick={ this.onToggleModalAdvancedSearchHandler }>
							<FontAwesomeIcon icon={ faSlidersH } className={ classnames('fa-2x', styles.icon) } />
						</div>
					</div>

					<LoadingOverlay active={ isLoading }>
						<div
							className={ classnames('uk-height-large', 'uk-overflow-auto', styles.list) }
							data-uk-height-viewport="offset-top: true; offset-bottom: 87px"
						>
							<ul className="uk-list uk-list-divider">
								{
									info.data && info.data.data && info.data.data.map((item, index) => (
										current === 'details' ? (
											<StockListItem
												key={ `page-stock-check-stock-list-item-${index}` }
												index={ index }
												item={ tempItem }
												lotNo={ item }
											/>
										) : (
											<StockListItem
												key={ `page-stock-check-stock-list-item-${index}` }
												index={ index }
												item={ item }
												onSelect={ this.onSelectItemHandler }
											/>
										)
									))
								}
							</ul>
						</div>

						<Pagination
							data={ info.data }
							onPageChange={ current === 'details' ? this.onChangeLotPageHandler : this.onChangePageHandler }
						>
							{ `${loc.totalResult}: ${info.data.total} / ${loc.totalQty}: ${info.data.stock_check_total_qty}` }
						</Pagination>
					</LoadingOverlay>
				</div>

				<ModalAdvancedSearch
					isOpen={ showModalAdvancedSearch }
					onToggle={ this.onToggleModalAdvancedSearchHandler }
					showShopFilter={ true }
					defaultShowAllShops={ true }
					param={ param }
					onChange={ this.onChangeParamHandler }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		stockInfo          : state.stockInfo,
		lotNoInfo          : state.lotNoInfo,
		lotItemInfo        : state.lotItemInfo,
		stockCheckItemCode : state.stockCheckItemCode,
	}),
	(dispatch) => ({
		getStock              : para => dispatch(getStock(para)),
		getLotNo              : para => dispatch(getLotNo(para)),
		getLotItem            : para => dispatch(getLotItem(para)),
		setStockCheckItemCode : para => dispatch(setStockCheckItemCode(para)),
	})
)(PageStockCheck);